<template>
  <div>
    <TransitionFade>
      <div class="w-full" v-if="show">
        <div v-if="showVideo" class="w-full h-full bg-white bg-opacity-50 flex items-center justify-center absolute z-100">
          <div class="w-full lg:w-3/5" v-click-away="closeVideoModal">
            <span class="absolute top-32 right-32 2xl:top-24 2xl:right-80 cursor-pointer text-white" aria-hidden="true" @click="closeVideoModal">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" style="filter: drop-shadow(0px 1px 1px rgb(0 0 0 / 0.4))" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
            <VideoGuide video="https://player.vimeo.com/video/778128908?h=f4bafe70f1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
          </div>
        </div>
        <div class="py-20 px-5 md:px-10 xs:p-0 mx-auto w-full">
          <div class="absolute top-5 right-5">
            <SelectLangs />
          </div>
          <div class="w-full">
            <div class="text-center">
              <div class="inline-flex space-x-6">
                <img src="https://acumbamail.com/static/public-theme/img/logos/acumbamail-black-logo.9f7ef42753fc.svg" class="w-60 my-10" />
                <div class="flex items-center" v-if="showPoweredBySetUp">
                  <div class="inline-flex items-center space-x-2 px-4 py-2 rounded-lg bg-white shadow-md poweredby">
                    <div class="text-sm font-semibold">{{ $t("acumbamailView.powered") }}</div>
                    <Brand size="small" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!loginLoader" class="flex flex-col w-full mx-auto sm:rounded-lg shadow-xl md:max-w-lg bg-white mt-3 divide-y divide-dashed">
              <div class="alert alert-error p-2.5 m-4" v-if="messageDomainInvalidate">
                <div class="flex-1">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        class="w-10 h-10 mt-2 mr-2 stroke-current">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                  </svg>
                  <label class="text-lg">{{ $t('acumbamailView.messageDomainInvalidate') }}</label>
                </div>
              </div>
              <div v-if="!messageDomainInvalidate" class="w-full py-8 px-6 md:px-8">
                <h3 class="text-gray-500 font-normal text-sm leading-tight">{{ $t("acumbamailView.platformAlready") }}</h3>
                <div class="w-full px-0 h-full flex-col justify-center items-center md:space-y-8">
                  <button v-if="!isOpenFormAcumbamail" @click="isOpenFormAcumbamail = true" class="flex items-center w-full justify-center mt-4 text-gray-600 hover:text-gray-700 rounded-lg shadow-md hover:bg-gray-50 border border-gray-200 transition relative hover:border-gray-300 focus:border-gray-300 focus:ring-gray-300 focus:outline-none focus:ring">
                    <div class="flex flex-row justify-evenly items-center">
                      <span class="px-0 py-3 flex-1 text-center font-semibold">{{ $t("acumbamailView.alreadyIHaveAnAccount") }}</span>
                    </div>
                  </button>
                  <div v-if="isOpenFormAcumbamail">
                    <div class="w-full my-2 mx-auto">
                      <div class="form-group">
                        <Message severity="info" :closable="false" icon="pi pi-info-circle">
                          <a href="https://help.setup.baymark.net/es/docs/acumbamail-crear-credenciales-de-acceso" target="_blank" class="underline">{{ $t("wizard.stepByStepForCreatignTheTokens") }}</a>
                        </Message>
                      </div>
                      <div>
                        <label for="" class="text-xs text-gray-500 uppercase">{{ $t("token") }}</label>
                        <input type="text" class="dl-input" placeholder="" v-model="vinculate.auth_token" />
                      </div>
                      <div>
                        <label for="" class="text-xs text-gray-500 uppercase">{{ $t("email") }}</label>
                        <input type="text" class="dl-input" placeholder="" v-model="vinculate.sender_email" />
                      </div>
                    </div>
                    <button @click="vinculateAccount()" class="flex items-center w-full justify-center mt-4 text-gray-600 hover:text-gray-700 rounded-lg shadow-md hover:bg-gray-50 border border-gray-200 transition relative hover:border-gray-300 focus:border-gray-300 focus:ring-gray-300 focus:outline-none focus:ring">
                      <div class="flex flex-row justify-evenly items-center">
                        <span class="px-0 py-3 flex-1 text-center font-semibold">{{ $t("acumbamailView.vinculateAccount") }}</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="!messageDomainInvalidate" class="w-full py-8 px-6 md:px-8">
                <h3 class="text-gray-800 font-semibold leading-tight">{{ $t("acumbamailView.createPlatformAsk") }}</h3>
                <div class="my-4">
                  <h3 class="text-gray-500 font-normal text-sm leading-tight">{{ $t("acumbamailView.before") }}</h3>
                  <button @click="openVideoModal" type="button" class="inline-flex items-center px-3 py-2 mt-1 mb-2 text-sm leading-4 font-medium bg-white text-gray-600 hover:text-gray-700 rounded-lg shadow-md hover:bg-gray-50 border border-gray-200 transition hover:border-gray-300 focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring"><VideoCameraIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" /> {{ $t("acumbamailView.integrationGuide") }}</button>
                </div>
                <button type="button" class="flex items-center w-full justify-center mt-4 text-white colorAcumbamail rounded-lg shadow-md border transition relative focus:outline-none focus:ring" @click="$router.push({ name: 'CreateAccountAcumbamail' })">
                  <span class="px-0 py-3 flex-1 text-center font-semibold">{{ $t("acumbamailView.createPlatform") }}</span>
                  <span class="absolute right-5"><Icon name="airplane" class="w-6 h-6 text-white stroke-current transform rotate-90" /></span>
                </button>
              </div>
            </div>
            <div v-if="loginLoader" class="flex flex-col w-full mx-auto sm:rounded-lg shadow-xl md:max-w-lg bg-white mt-3 divide-y divide-dashed">
              <h1 class="py-8 text-center text-gray-700 font-semibold leading-tight">{{ $t("acumbamailView.loading") }}</h1>
              <div class="p-10 flex flex-col justify-center items-center">
                <Spinner color="blue" size="small" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransitionFade>
  </div>
</template>

<script>
import SelectLangs from "@/components/SelectLangs"
import Message from "primevue/message"
import Brand from "@/components/Brand"
import Icon from "@/components/Icon"
import TransitionFade from "@/components/TransitionFade"
import axiosClient from "@/config/AxiosClient"
import VideoGuide from "@/components/UI/VideoGuide"
import Spinner from "@/components/UI/Spinner"
import { VideoCameraIcon } from "@heroicons/vue/solid"

export default {
  name: "AcumbamailLogin",
  components: {
    SelectLangs,
    Message,
    Brand,
    Icon,
    TransitionFade,
    VideoGuide,
    VideoCameraIcon,
    Spinner
    // SelectLangs,
  },
  computed: {
    urlRedirect() {
      return `${window.location.origin}/a/login`
    }
  },
  data: function () {
    return {
      isOpenFormAcumbamail: false,
      showPoweredBySetUp: false,
      show: false,
      showVideo: false,
      login: false,
      driver: localStorage.getItem("driver"),
      account_uuid: localStorage.getItem("account_uuid"),
      loginLoader: false,
      vinculate: {
        auth_token: null,
        sender_email: null
      },
      messageDomainInvalidate: false
    }
  },
  async mounted() {
    if(this.$route.query.domainInvalidate){
      this.messageDomainInvalidate = true
      this.show = true
      return;
    }
    if (this.$route.query.driver != undefined) {
      this.driver = this.$route.query.driver
      this.account_uuid = this.$route.query.account_uuid
      localStorage.setItem("driver", this.driver)
      localStorage.setItem("account_uuid", this.account_uuid)
    }
    if (this.$route.query.access_token) this.loginSuccess(this.$route.query.access_token, this.$route.query.user_social_id)
    this.show = true

    if (this.account_uuid) {
      let data = await axiosClient.get(`/api/2.0/account/${this.account_uuid}/form`)
      this.vinculate.sender_email = data.email || ""
    }
  },
  methods: {
    loginSuccess: async function (token, user_social_id) {
      this.loginLoader = true

      await this.$store.dispatch("Auth/loginSuccess")
      await this.$store.dispatch("Auth/setTokenLocalStorage", token)
      await this.$store.commit("Auth/setExternalLogin", true)
      await this.$store.dispatch("Auth/getUser")

      let response = await axiosClient.post(`/api/2.0/vinculate/account${this.account_uuid != null ? `/${this.account_uuid}` : ""}`, { user_social_id, channel_output_id: 19 })

      let redirect = async (accounts) => {
        await this.$store.commit("Accounts/setAccount", accounts)

        if(accounts[0].data_output != null) {
          setTimeout(() => {
            this.$router.push({ name: "DashboardAcumbamail" })
          }, 1000)
        }

        if(accounts[0].data_output == null) {
          this.loginLoader = false;
        }
      };
      
      if(response.data.tenant) {
        let checkTenant = setInterval(async () => {
          let tenantResponse = await axiosClient.post(`/api/2.0/vinculate/account${this.account_uuid != null ? `/${this.account_uuid}` : ""}`, { user_social_id, channel_output_id: 19 })

          if(tenantResponse.data.accounts) {
            clearInterval(checkTenant);

            await redirect(tenantResponse.data.accounts);
          }
        }, 5000);

        return true;
      }

      await redirect(response.data.accounts);
    },
    openVideoModal: function () {
      this.showVideo = true
    },
    closeVideoModal: function () {
      this.showVideo = false
    },
    async vinculateAccount() {
      this.loginLoader = true
      await axiosClient.post(`/api/2.0/account/set/dataOutput/${this.account_uuid}`, { data_output: this.vinculate }, { headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` } })
      setTimeout(() => {
        this.$router.push({ name: "DashboardAcumbamail" })
      }, 1000)
    }
  }
}
</script>
